import React, { forwardRef, useEffect, useRef, useState } from "react"
import ReactDatePicker from "react-datepicker"
import { TextField } from '@designsystem'
import { Icon, Pill } from '@design-system'

interface DatepickerProps {
  variant?: 'input' | 'pill'
  defaultSelected: string
  name: string
  error?: string
  placeholder?: string
  minDate?: string
  maxDate?: string
}

function Datepicker({ variant, defaultSelected, name, error, placeholder, minDate, maxDate, ...rest }: DatepickerProps) {
  const [selectedDate, setSelectedDate] = useState(new Date(defaultSelected))

  const handleChangeDate = (date: Date) => {
    setSelectedDate(date)
  }

  return <>
    <ReactDatePicker
      selected={selectedDate}
      onChange={handleChangeDate}
      name={name}
      popperClassName="!z-10"
      /* @ts-expect-error */
      customInput={<DatePickerInputButton variant={variant} error={error} onChange={handleChangeDate} />}
      minDate={new Date(minDate)}
      maxDate={new Date(maxDate)}
      {...rest}
    />
  </>
}

const DatePickerInputButton = forwardRef((
  { variant, name, value, error, onClick, placeholder, className }: { variant?: 'input'| 'pill', name: string, error?: string, fullWidth: boolean, value: string, placeholder?: string, className?: string, onClick?: () => void },
  ref: React.ForwardedRef<HTMLButtonElement>
) => {
  const inputRef = useRef(null)
  const isFirstRenderRef = useRef(null)

  useEffect(() => {
    if (!isFirstRenderRef.current) {
      isFirstRenderRef.current = true
      return
    }
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value').set;
    nativeInputValueSetter.call(inputRef.current, value);
    const event = new Event('input', { bubbles: true });
    inputRef.current.dispatchEvent(event);
  }, [value])

  if (variant === 'pill') {
    return (
      <Pill color="gray-20" onClick={onClick} className="cursor-pointer">
        <Pill.Icon name="calendar" />
        <input
          type='text'
          ref={inputRef}
          name={name}
          placeholder={placeholder}
          defaultValue={value}
          className="bg-transparent border-none h-full text-xxs p-0 w-20"
        />
        <Pill.Icon name="chevron-down" />
      </Pill>
    )
  }

  return (
    <TextField
      type='text'
      ref={inputRef}
      name={name}
      error={error}
      placeholder={placeholder}
      defaultValue={value}
      onClick={onClick}
      endAdornment={<Icon name='chevron-down' />}
      className={className}
    />
  )
})

export { Datepicker as unstable_Datepicker }
