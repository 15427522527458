import React, { Children, useRef, createContext, useContext, PropsWithChildren, Fragment } from 'react'
import { Button, unstable_Popover as Popover, Command, Icon, Typography, cn, Spinner, PopoverContentType, Pill } from "@design-system"

const getOptions = (children: React.ReactNode) => {
  const options = Children.toArray(children)
    .map((child: React.ReactElement) => {
      if (typeof child === 'object' && child.type && (child.type as React.ComponentType<any>).displayName === 'Combobox.Item') {
        return { value: child.props.value, children: child.props.children }
      } else if (typeof child === 'object' && child.type && (child.type as React.ComponentType<any>).displayName === 'Combobox.Group') {
        return getOptions(child.props.children)
      }
    })
  return options.flat()
}

interface ComboboxContextType {
  multiple: boolean
  selectedValue: string | string[]
  loading: boolean
  variant: 'button' | 'pill' | 'borderless' | 'freeform'
  handleChange: (value: any) => void
  setOpen: (value: boolean) => void
}

const ComboboxContext = createContext<ComboboxContextType | undefined>(undefined);

export const useComboboxContext = () => {
  const context = useContext(ComboboxContext)
  if (!context) {
    throw new Error('useComboboxContext must be used within a ComboboxProvider')
  }
  return context
}

interface RootProps extends PropsWithChildren, React.ComponentPropsWithoutRef<'select'> {
  label?: string
  name: string
  multiple?: boolean
  placeholder?: string
  emptyText?: string
  search?: React.ReactNode
  defaultValue?: string | string[]
  helperText?: string
  error?: string | boolean
  loading?: boolean
  prefix?: string
  variant?: 'button' | 'pill' | 'borderless' | 'freeform'
  customTrigger?: React.ReactNode
  renderMultipleItem?: (item: string, index: number) => React.ReactNode
  /**
   * If true, the combobox popover will be rendered inside a Portal.
   */
  asPopoverPortal?: boolean
  popoverContentProps?: PopoverContentType | { side: 'top' | 'bottom' | 'left' | 'right', align: 'start' | 'center' | 'end' }
}

const RootButton = ({ variant, children, error, disabled, open }) => {
  if (variant === 'freeform') {
    return (
      <Popover.Trigger asChild>
        {children}
      </Popover.Trigger>
    )
  }
  if (variant === 'borderless') {
    return (
      <Popover.Trigger asChild>
        <button role="combobox" className={cn("font-bold cursor-pointer flex justify-between items-center gap-2 w-full", Boolean(error) && "border-danger")} aria-expanded={open} disabled={disabled}>
          {children}
        </button>
      </Popover.Trigger>
    )
  }
  if (variant === 'pill') {
    return (
      <Popover.Trigger asChild>
        <Pill role="combobox" asChild size="sm" color="gray-20" className={cn("cursor-pointer flex justify-between gap-2", Boolean(error) && "!border-danger")}>
          <button aria-expanded={open} disabled={disabled}>
            {children}
          </button>
        </Pill>
      </Popover.Trigger>
    )
  }
  return (
    <Popover.Trigger asChild>
      <Button
        variant="input"
        size="lg"
        disabled={disabled}
        role="combobox"
        aria-expanded={open}
        className={cn("w-full flex justify-between gap-2", Boolean(error) && "!border-danger")}
      >
        {children}
      </Button>
    </Popover.Trigger>
  )
}
export function Root({ label, renderMultipleItem, variant, name, multiple, placeholder, emptyText, search, children, defaultValue, className, required, disabled, helperText, error, loading, asPopoverPortal, popoverContentProps, prefix, customTrigger, ...rest }: RootProps) {
  const options = getOptions(children)
  const [open, setOpen] = React.useState(false)
  const [selectedValue, setSelectedValue] = React.useState(multiple ? defaultValue ?? [] : defaultValue ?? '')
  const [selectedChildren, setSelectChildren] = React.useState(multiple ? undefined : options.find(option => option?.value === defaultValue)?.children)
  const [selectedPrefix, setSelectPrefix] = React.useState(multiple ? undefined : options.find(option => option?.value === defaultValue)?.prefix)
  const selectRef = useRef(null)
  const PopoverSlot = asPopoverPortal ? Popover.Portal : React.Fragment

  const handleOpen = (value) => {
    if (loading) return
    setOpen(value)
  }

  const handleChange = ({ value, children, prefix }) => {
    const dispatchChangeEvent = () => {
      const event = new Event('change', { bubbles: true })
      selectRef.current.dispatchEvent(event)
    }

    if (multiple) {
      setSelectedValue(prev => {
        const isValueSelected = prev.includes(value)
        /* @ts-ignore */
        const newValue = isValueSelected ? prev.filter(v => v !== value) : [...prev, value]
        const options = selectRef.current.options;
        for (let i = 0; i < options.length; i++) {
          options[i].selected = newValue.includes(options[i].value)
        }
        dispatchChangeEvent()
        return newValue
      })
    } else {
      setSelectedValue(value)
      setSelectPrefix(prefix)
      setSelectChildren(children)
      setOpen(false)
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLSelectElement.prototype, 'value').set
      nativeInputValueSetter.call(selectRef.current, value)
      dispatchChangeEvent()
    }
  }

  return (
    <div className={cn("space-y-1", className)}>
      {label && (
        <Typography asChild variant="callout">
          <label htmlFor={name}>
            {label}
            {required && (!helperText || (error && typeof error !== 'string')) && <Required />}
          </label>
        </Typography>
      )}
      <ComboboxContext.Provider value={{ multiple, selectedValue, handleChange, loading, variant, setOpen }}>
        <Popover modal={true} open={open} onOpenChange={handleOpen}>
          {variant === 'freeform' ? (
            <RootButton variant={variant} error={error} disabled={disabled} open={open}>
              {customTrigger}
            </RootButton>
          ) : (
            <RootButton variant={variant} error={error} disabled={disabled} open={open}>
              {multiple &&
                <>
                  {Array.isArray(selectedValue) && selectedValue.length > 0 && renderMultipleItem ? (
                    <div className={cn("overflow-hidden flex gap-2", variant === 'borderless' && 'flex-wrap')}>
                      {selectedValue.map((item, index) => <Fragment key={item}>{renderMultipleItem(item, index)}</Fragment>)}
                    </div>
                  ) : <Typography variant={variant === 'pill' ? 'footnote' : undefined} weight={variant === 'pill' ? 'bold' : undefined} color={variant === 'pill' ? undefined : 'secondary'}>{placeholder}</Typography>}
                  {selectedValue.length > 0 && (
                    <span className="rounded-full shrink-0 bg-gray-100 dark:bg-gray-2 text-white dark:text-gray-100 text-xxs font-bold py-0.5 px-2 ml-auto">{selectedValue.length}</span>
                  )}
                </>
              }
              {prefix && <section className='truncate'>{prefix}</section>}
              {!multiple && <section className='truncate'>{selectedPrefix ? `${selectedPrefix} - ` : null}{selectedChildren ? selectedChildren : (<Typography variant={variant === 'pill' ? 'footnote' : undefined} weight={variant === 'pill' ? 'bold' : undefined} color={variant === 'pill' ? undefined : 'secondary'}>{placeholder}</Typography>)}</section>}
              {loading ? <Spinner size={variant === 'pill' ? 'xs' : 'sm'} /> : <Icon size={variant === 'pill' ? 'xs' : 'md'} name="chevron-down" className="shrink-0" />}
            </RootButton>
          )}
          <PopoverSlot>
            <Popover.Content onOpenAutoFocus={e => e.preventDefault()} className="mb-20 p-0 max-h-[var(--radix-popover-trigger-height)] w-full min-w-[var(--radix-popover-trigger-width)]" {...popoverContentProps}>
              <Command>
                {search}
                <Command.List>
                  <Command.Empty>{emptyText ?? "No results"}</Command.Empty>
                  {children}
                </Command.List>
              </Command>
            </Popover.Content>
          </PopoverSlot>
        </Popover >
        <select
          {...rest}
          ref={selectRef}
          name={name}
          multiple={multiple}
          className="visually-hidden"
          aria-hidden="true"
          tabIndex={-1}
          defaultValue={selectedValue}
          required={required}
          disabled={disabled}
        >
          <option value=""></option>
          {options.filter(option => option?.value).map((option) => (
            <option key={option.value} value={option.value}>
              {option.value}
            </option>
          ))}
        </select>
      </ComboboxContext.Provider >
      <div>
        {typeof error === 'string' && (
          <Typography id="error" variant="footnote" color="danger" className="flex items-center gap-1">
            <Icon size="2xs" name="alert-triangle-filled" />
            <span>
              {error}
              {required && <Required />}
            </span>
          </Typography>
        )}
        {typeof error !== 'string' && helperText && (
          <Typography variant="footnote" color="tertiary">
            {helperText}
            {required && <Required />}
          </Typography>
        )}
      </div>
    </div >
  )
}
Root.displayName = 'Combobox'

const Required = () => (
  <span className="text-danger font-bold">
    {' *'}
  </span>
)

const Group = React.forwardRef<
  React.ElementRef<typeof Command.Group>,
  React.ComponentPropsWithoutRef<typeof Command.Group>
>(({ ...props }, ref) => (
  <Command.Group
    ref={ref}
    {...props}
  />
))
Group.displayName = 'Combobox.Group'

const Item = React.forwardRef<
  React.ElementRef<typeof Command.Item>,
  React.ComponentPropsWithoutRef<typeof Command.Item>
>(({ value, children, prefix, ...props }, ref) => {
  const { multiple, selectedValue, handleChange, loading, variant } = useComboboxContext()
  const isSelected = multiple ? selectedValue.includes(value) : selectedValue === value
  return (
    <Command.Item
      ref={ref} {...props} onSelect={() => handleChange({ value, children, prefix })}>
      {children}
      {(multiple && loading && variant === 'freeform' && isSelected) && (	
        <Spinner size="xs" className="ml-auto" />
      )}
      {((!loading && variant === 'freeform' && isSelected) || (variant !== 'freeform' && isSelected)) && (
        <Icon name="check" size="xs" className="ml-auto" />
      )}
    </Command.Item>
  )
})
Item.displayName = 'Combobox.Item'

const ItemAction = React.forwardRef<
  React.ElementRef<typeof Command.Item>,
  React.ComponentPropsWithoutRef<typeof Command.Item> & {
    onSelect?: () => void
  }
>(({ children, onSelect, ...props }, ref) => {
  const { setOpen } = useComboboxContext()
  
  const handleSelect = () => {
    onSelect?.()
    setOpen(false)
  }

  return (
    <Command.Item
      ref={ref}
      {...props}
      onSelect={handleSelect}
      className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-2"
    >
      {children}
    </Command.Item>
  )
})
ItemAction.displayName = 'Combobox.ItemAction'

const Separator = () => (
  <div className='px-5'>
    <Command.Separator/>
  </div>
)
Separator.displayName = 'Combobox.Separator'

const Search = React.forwardRef<
  React.ElementRef<typeof Command.Input>,
  React.ComponentPropsWithoutRef<typeof Command.Input>
>(({ ...props }, ref) => (
  <Command.Input
    ref={ref}
    {...props}
  />
))
Search.displayName = 'Combobox.Search'

/**
 * Autocomplete input and command palette with a list of suggestions.
 */
export const Combobox = Object.assign(Root, { Item, Search, Group, Separator, ItemAction })
