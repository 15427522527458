import * as React from 'react'
import { Transition } from '@headlessui/react'
import { cva, cx, type VariantProps } from 'class-variance-authority'
import { Button, Icon, Typography } from '@design-system'

const drawerVariants = cva('relative bg-white dark:bg-gray-90 h-full shadow-xl flex flex-col', {
  variants: {
    width: {
      sm: 'w-[350px]',
      md: 'w-[500px]',
      lg: 'w-[700px]'
    }
  },
  defaultVariants: {
    width: 'md'
  }
})

type DrawerProps = React.PropsWithChildren & VariantProps<typeof drawerVariants> & {
  isOpen: boolean
  onClose: () => void
}

function Root({ isOpen, onClose, width, children }: DrawerProps) {
  return (
    <Transition show={isOpen} appear as={React.Fragment}>
      <div className="fixed inset-0 z-50 flex justify-end">
        <Transition.Child
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute inset-0 bg-black opacity-80 transition-opacity" onClick={onClose} />
        </Transition.Child>

        <Transition.Child
          enter="transition-transform duration-300"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transition-transform duration-300"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div className={drawerVariants({ width })} data-testid="drawer">
            <Button
              asChild
              variant="ghost"
              className="absolute right-4 top-4"
              onClick={onClose}
            >
              <Icon name="cross" />
            </Button>
            {children}
          </div>
        </Transition.Child>
      </div>
    </Transition>
  )
}

function Title({ children }: { children: React.ReactNode }) {
  return (
    <div className="p-4 border-b">
      <Typography weight="bold" variant="heading-3">{children}</Typography>
    </div>
  )
}

function Content({ children, className }: { children: React.ReactNode, className?: string }) {
  return (
    <div className={cx("p-4 flex-1 overflow-y-auto", className)}>
      {children}
    </div>
  )
}

function Footer({ children }: { children: React.ReactNode }) {
  return (
    <div className="p-4 border-t">
      {children}
    </div>
  )
}

export const Drawer = Object.assign(Root, { Title, Content, Footer })