import * as React from 'react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { cn } from '../../lib/cn'
import { Icon } from '../../atoms/icon'
import { Typography } from '../../atoms/typography'
import { Spinner } from '../../atoms/spinner'

interface CheckboxProps extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  label?: string
  helperText?: string
  error?: string | boolean
  loading?: boolean
}

export const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(({ className, label, helperText, error, loading, disabled, ...props }, ref) => {
  const id = React.useId()

  return (
    <div className="space-y-1">
      <div className="flex items-center space-x-2">
        <CheckboxPrimitive.Root
          ref={ref}
          id={id}
          className={cn(
            `w-6 h-6 rounded border border-gray-30 dark:border-gray-80 peer shrink-0 bg-white dark:bg-gray-100 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-accent dark:data-[state=checked]:bg-accent`,
            Boolean(error) && "border-danger",
            className
          )}
          disabled={disabled || loading}
          {...props}
        >
          {loading ? (
            <div className="flex items-center justify-center">
              <Spinner size="xs" />
            </div>
          ) : (
            <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center text-current')}>
              <Icon name="check" size="xs" className="text-white" />
            </CheckboxPrimitive.Indicator>
          )}
        </CheckboxPrimitive.Root>
        {label && (
          <Typography
            asChild
            variant="callout"
            className="peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            color={error ? 'danger' : 'primary'}
          >
            <label htmlFor={id}>{label}</label>
          </Typography>
        )}
      </div>
      {(helperText || error) && (
        <Typography
          variant="footnote"
          color={error ? 'danger' : 'tertiary'}
        >
          {typeof error === 'string' ? error : helperText}
        </Typography>
      )}
    </div>
  )
})
Checkbox.displayName = CheckboxPrimitive.Root.displayName

