import React, { createContext, useContext, useState, ReactNode, ComponentType } from 'react'
import { Dialog } from '@design-system'

interface ModalContextType {
  openModal: (config: ModalConfig) => void
  closeModal: () => void
}

interface ModalConfig {
  title: string
  Form?: ComponentType<{ children: ReactNode }>
  content: ReactNode
  footer?: ReactNode
  size?: 'xs' | 'sm' | 'md' | 'lg'
  onClose?: () => void
}

const ModalContext = createContext<ModalContextType | null>(null)

export const useModal = () => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider')
  }
  return context
}

interface ModalProviderProps {
  children: ReactNode
}

interface ModalContentProps {
  title: string
  content: ReactNode
  footer?: ReactNode
}

const ModalContent = ({ title, content, footer }: ModalContentProps) => (
  <>
    <Dialog.Header className='p-4 border-b'>
      <Dialog.Title>{title}</Dialog.Title>
      <Dialog.Close />
    </Dialog.Header>
    <Dialog.Body className='p-4'>
      {content}
    </Dialog.Body>
    {footer && <Dialog.Footer className='p-4 border-t'>{footer}</Dialog.Footer>}
  </>
)

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [config, setConfig] = useState<ModalConfig | null>(null)

  const openModal = (newConfig: ModalConfig) => {
    setConfig(newConfig)
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
    if (config?.onClose) {
      config.onClose()
    }
    // Reset config after a short delay to ensure animations complete
    setTimeout(() => {
      setConfig(null)
    }, 100)
  }

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      {config && (
        <Dialog open={isOpen} onClose={closeModal}>
          <Dialog.Content side="center" size={config.size || 'md'} className='p-0'>
            {config.Form ? (
              <config.Form>
                <ModalContent title={config.title} content={config.content} footer={config.footer} />
              </config.Form>
            ) : (
              <ModalContent title={config.title} content={config.content} footer={config.footer} />
            )}
          </Dialog.Content>
        </Dialog>
      )}
    </ModalContext.Provider>
  )
}

export const Modal = {
  Provider: ModalProvider,
  useModal,
} 